<template>
  <fragment>
    <div class="form-group mb-0">
      <label
        for="phone-input"
        class="p-0"
        style="top: 5px; position: relative; color: rgba(0, 0, 0, 0.6)"
        >Telefon Numarası</label
      >
      <input
        v-model="number"
        type="text"
        class="form-control"
        id="phone-input"
        ref="phone-input"
        @input="handleInputChange"
        @change="changePhone"
      />
      <span class="text-danger" v-if="errors" v-html="errors"></span>
    </div>
    <v-text-field style="display: none" :error-messages="errors"></v-text-field>
  </fragment>
</template>

<script>
import intlTelInput from "intl-tel-input";

export default {
  name: "SMPhoneSelectbox",
  props: {
    phone: {
      type: String,
      default: null,
    },
    validatable: {
      type: Boolean,
      default: false,
    },
    validateId: Number,
    isFormValid: Boolean,
    checkUserPhone: String,
  },
  data() {
    return {
      number: null,
      lastNumber: null,
      errors: "",
      phoneInput: null,
    };
  },
  mounted() {
    this.phoneInput = intlTelInput(this.$refs["phone-input"], {
      initialCountry: "TR",
      formatOnDisplay: true,
      preferredCountries: ["TR"],
      dropdownContainer: document.body,
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.6/js/utils.min.js",
    });

    this.number = this.phone ?? null;
  },
  methods: {
    async handleInputChange() {
      this.errors = null;

      if (!this.phoneInput.isValidNumber()) {
        this.errors = "Telefon numarası geçerli değil";
        return;
      }

      const phone = this.phoneInput.getNumber();

      if (this.checkUserPhone && this.checkUserPhone === phone) {
        this.errors = "Bu numara, sizin numaranızla aynı olamaz.";
      }

      if (this.validatable) {
        try {
          await this.$apiService.post("/phone/verify", {
            user_id: this.validateId,
            phone,
          });
        } catch (error) {
          this.errors = "Bu telefon numarası zaten kayıtlı";
          return;
        }
      }

      this.lastNumber = phone;
    },
    changePhone() {
      this.$emit("update:phone", this.lastNumber);
    },
  },
};
</script>
